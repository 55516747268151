<template>
  <div class="main-all">
    <div class="center-box">
      <div class="container-score">
        <div class="name">
          <h3>{{$store.state.userInfo.examName}}</h3>
          <p>您本次客观考试成绩为：</p>
        </div>
        <div class="score-img">
          <div>
            <span class="score-num">{{$store.state.userInfo.resultScore.examScore}}</span>
            <span class="score-txt">得分</span>
          </div>
        </div>
      </div>
      <p class="tips">注：上述成绩不代表您本次考试是否通过。如需了解更多详情请进入APP考试报名模块查看《报考指南》。</p>
    </div>
    <div class="foot-bg">
      <img :src="FootBg1" alt="">
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      FootBg1: require('@/assets/image/foot-bg1.png'),  // 未通过时分数框
      FootBg2: require('@/assets/image/foot-bg2.png'),  // 未通过时分数框
    }
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.main-wrap{
  position: relative;
}
.center-box{
  position: relative;
  bottom: 40px;
  padding: 6px;
  width: 45%;
  margin: 0 auto;
  z-index: 100;
  background: rgba(255,255,255,0.2);
  box-shadow: 4px 4px 20px 0px rgba(46,124,222,0.25);
  border-radius: 27px;
  .container-score{
    text-align: center;
    padding: 38px 0;
    border-radius: 27px;
    background: #ffffff;
    .name{
      display: inline-block;
      text-align: left;
      >h3{
        font-size: 32px;
        font-weight: 500;
        color: #000000;
      }
      >p{
        margin-top: 24px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
      }
    }
    
    .score-img{
      margin:  0 auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 14vw;
      height: 14vw;
      background: url('../assets/image/score-bg3.png');
      background-size: 100% 100%;
      .score-num{
        font-size: 60px;
        font-family: DIN Alternate-Bold, DIN Alternate;
        font-weight: bold;
        color: #333333;
        line-height: 70px;
      }
      .score-txt{
        display: block;
        width: 100%;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
      }
    }
  }
  .tips{
    padding: 12px 16px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #909aa3;
    line-height: 22px;
    &::before{
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      background: rgba(46,110,222,0.3);
      border-radius: 50%;
      margin-right: 4px;
      margin-top: -3px;
    }
  }
}
.foot-bg{
  position: absolute;
  bottom: 0;
  height: 70vh;
  width: 100%;
  background: url('../assets/image/foot-bg2.png');
  background-size: 100% 100%;
  img{
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .center-box{
    width: 58%;
  }
  h3{
    font-size: 26px !important;
  }
  .score-img{
    width: 17vw !important;
    height: 17vw !important;
  }
  .score-img{
    /deep/.score-num{
      font-size: 40px !important;
      line-height: 50px !important;
    }
  }
}
@media screen and (max-width: 850px) {
  h3{
    font-size: 20px !important;
  }
  .score-img{
    /deep/.score-num{
      font-size: 30px !important;
      line-height: 40px !important;
    }
    /deep/.score-txt{
      font-size: 12px !important;
    }
  }
}
</style>
